
<template>
  <div>
    <el-form ref="data" :model="obj" label-width="80px">
      <el-form-item label="商品名称">
        <el-input v-model="obj.title"></el-input>
      </el-form-item>
      <el-form-item label="商品原料">
        <el-input v-model="obj.yuanliao"></el-input>
      </el-form-item>
      <el-form-item label="商品金额">
        <el-input v-model="obj.money"></el-input>
      </el-form-item>
      <el-form-item label="商品类型">
        <template>
          <el-radio-group v-model="obj.num" @change="changeRadio">
            <el-radio :label="item.fenlei_id" v-for="(item, index) in classList" :key="index" :value="item.text">{{item.text}}</el-radio>
          </el-radio-group>
        </template>
      </el-form-item>
      <el-form-item label="商品图片">
       <el-upload
    class="avatar-uploader"
    :action="serverUrl"
    :show-file-list="false"
    :on-success="uploadSuccess"
    :before-upload="uploadBefore"
    accept="image/*"
   >
   	 <img v-if="imgUrl" :src="imgUrl" class="avatar" />
   	 <i v-else class="el-icon-plus avatar-uploader-icon"></i>
   </el-upload>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="btn">确认添加</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  data() {
    return {
		newUrl:'11111',
      classList:[],
      obj: {
        img: "", 
        title: "",
        yuanliao: "", 
        fenlei_id: "", 
        money: "", 
      },
       imgUrl:"",
    };
  },
  created(){
    this.getClassList();

  },
  computed:{
    ...mapGetters(['serverUrl'])
	  
  },
  methods: {
     //图片上传成功
    uploadSuccess(res, file) {
      this.imgUrl = URL.createObjectURL(file.raw);
      this.obj.img = res.imgUrl; // 将图片路劲保存在数据库中
      console.log(this.obj.img)
      console.log(res.imgUrl)
    },
    //图片上传之前
    uploadBefore(file) {
      let limitMax = 5000 * 1024;
      if (file.size > limitMax) {
        this.$messageTips("大小超出限制");
        return false;
      }
    },

   // 获取分类
  async getClassList() {
      const { data: res } = await this.$http.get('getClassTabs')
      this.classList = res.list
  },

  
  // 点击确认，发布咖啡
// 点击确认，发布咖啡
async btn() {
  // 检查所有属性是否为空
  if (!this.obj.img) {
    this.$message.error("请上传图片！");
    return;
  }
  if (!this.obj.title) {
    this.$message.error("请填写标题！");
    return;
  }
  if (!this.obj.yuanliao) { this.$message.error("请填写原料！");
    return;
  }
  if (!this.obj.fenlei_id) {
    this.$message.error("请选择分类！");
    return;
  }
  if (!this.obj.money) {
    this.$message.error("请填写价格！")
    return;
  }

  // 所有属性都填写完毕，发送请求
  try {
    const { data: res } = await this.$http.post("addData", this.obj);
    // 处理响应数据
    console.log(res);
    this.$message.success("添加成功！");
    this.$router.push('/list');
  } catch (error) {
    // 处理错误
    console.error("Error during add:", error);
    this.$message.error("失败，请稍后再试！");
  }
},

       changeRadio(value){
      console.log(value);
      this.obj.fenlei_id=value
    },

  },
};
</script>

<style>
.avatar-uploader {
  width: 200px;
  height: 178px;
  border: 1px dashed gray;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 200px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 200px;
  height: 178px;
  display: block;
}

</style>